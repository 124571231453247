import React from 'react';
import styles from './PacLink.module.css';
import Header from '../../components/header/Header';
import { Button, Input, notification, Tooltip } from 'antd';
import { useMediaQuery } from 'react-responsive';
import HelpIcon from '../../components/HelpIcon/HelpIcon';
import { useCurrentUserContext } from '../../store';
import { API } from 'aws-amplify';
import { ArrowRightOutlined } from '@ant-design/icons';

const isMobileOrTablet = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const redirectMap = {
  "https://dev-onboarding.timetoceli.com": "https://app-dev.timetoceli.com/",
  "https://test-onboarding.timetoceli.com": "https://app-test.timetoceli.com/",
  "https://onboarding.timetoceli.com": "https://app.timetoceli.com/",
  "http://localhost:3000": "http://localhost:3001",
}

export default function PacLink() {
  const { currentUser, loadUser } = useCurrentUserContext();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return <div className={styles.main}>
    <Header username />
    <div className={styles.content}>
      <h1 className={styles.header}>Save this link</h1>
      <PaclInput />
      {isDesktopOrLaptop ?
        <p className={styles.text}>
          When someone uses your PAC link to create a Celi account, we’ll auto-connect your profiles.<br />
          Their dates will display in your dash and Celi will remind you anytime they have something to celi.
        </p> :
        <p className={styles.text}>
          When someone uses this link to create an account, we’ll auto-connect your profiles and remind you anytime they have something to celi.
        </p>}

      {isDesktopOrLaptop ? <img src="chat-bubbles-desktop.png" alt="chat sharing pac link" style={{
        width: "35%",
        paddingBottom: "25px",
      }} /> : <img src="chat-bubbles-mobile.png" alt="chat sharing pac link" style={{
        width: "100%",
        paddingBottom: "25px",
      }} />}

      <Button
        type='default'
        size='large'
        className={styles.button}
        href={redirectMap[window.location.origin] || 'https://app.timetoceli.com'}
      >
        Go to dashboard  <ArrowRightOutlined />
      </Button>
    </div>
  </div>
}

export const copiedPacLink = async (id) => {
  try {
    const apiName = "api";
    const path = "/api/user/copyPacLink";
    const init = {
      body: {
        id: id,
      },
    };

    const res = await API.post(apiName, path, init);
    return res
  } catch(error) {
  } finally {
  }
};

const PaclInput = () => {
  const { currentUser, loadUser } = useCurrentUserContext();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const pacl = `https://onboarding.timetoceli.com?ref=${currentUser.approvedConnectionLinkId}`;

  const onCopy = () => {
    if(!isMobileOrTablet()) {
      navigator.clipboard.writeText(pacl);
      notification.success({
        message: "PAC Link Copied!",
        description: "When someone creates a profile using this link, Celi auto-connects you. Like magic, their dates display in your dash, and you receive reminders whenever they have a date to celi.",
        placement: "top",
        duration: 0,
      });
      // copiedPacLink(currentUser.id)
    } else {
      const shareData = {
        title: "Celi",
        text: "I just started using Celi to better remember birthdays and other important dates. Yours are important to me, will you add them to my Celi?",
        url: pacl,
      };


      navigator.share(shareData).then(response => null).catch(err => {
        console.log({ err });
      })
    }

  };

  return (
    <div className={styles.paclWrapper}>
      <div className={styles.paclInputWrap}>
        <Input
          size="large"
          className={styles.paclInput}
          disabled
          suffix={
            <Button className={styles.copyButton} size="small">
              {!isMobileOrTablet() ? "Copy" : "Share"}
            </Button>
          }
          value={pacl}
        />
        <div className={styles.paclClick} onClick={onCopy}></div>
      </div>
    </div>
  );
};