import React, { Component, ReactNode } from 'react';


interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de usuario de error
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Puedes también enviar el error a un servicio de registro de errores
    console.error(error, errorInfo);
  }

  render() {
    if(this.state.hasError) {
      // Puedes personalizar el mensaje de error como desees
      // return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
