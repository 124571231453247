import React, { useEffect, useState } from 'react';
import './App.css';
import './globals.css';
import { API, Auth, Hub } from 'aws-amplify';
// import Main from './Main';
import {
  Authenticator,
  ThemeProvider,
  withAuthenticator,
} from '@aws-amplify/ui-react';
// import UnauthApp from './UnauthApp/UnauthApp';
// import AuthApp from './AuthApp/AuthApp';
import {
  AuthUserContext,
  CurrentUserContext,
  PreapprovedConnectionLinkContext,
} from './store';
import {
  Navigate,
  RouterProvider,
  redirect,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import { ConfigProvider, Image } from 'antd';

export interface SignInParameters {
  username: string;
  password: string;
}

const App = () => {
  // const [isAuth, setIsAuth] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadUser = async () => {
    if(!currentUser) {
      setLoading(true);
    }

    try {
      const apiName = 'api';
      const path = '/api/users/currentUser';
      const init = {};

      const res = await API.get(apiName, path, init);
      const user = await res.data;
      setCurrentUser(user);
    } catch(error) {
    } finally {
      setLoading(false);
    }
  };

  const getAuthentication = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch(e) {
      return null;
    }
  };

  async function signIn({ username, password }: SignInParameters) {
    try {
      const user = await Auth.signIn(username, password);
      setAuthUser(user);
      await loadUser();
    } catch(error) {
    } finally {
      // window.location.reload();
    }
  }

  async function signOut() {
    try {
      const response = await Auth.signOut();
      setAuthUser(null);
      setCurrentUser(null);
    } catch(error) {
    } finally {
      // window.location.reload();
    }
  }

  useEffect(() => {
    getAuthentication().then((resp) => {
      resp && setAuthUser(resp);
      resp && loadUser();
      !resp && setLoading(false);
    });
  }, []);

  if(loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <div>
          <Image
            preview={false}
            src={'celi-logo-transp 2.png'}
            style={{ height: '200px', width: '200px' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="App">
        <ConfigProvider
          theme={{
            // token: {
            //   fontSizeHeading3: 36,
            // },
            components: {
              //   Table: {
              //     headerBg: 'black',
              //     headerColor: 'white',
              //     headerSplitColor: 'none',
              //   },
              Button: {
                colorPrimary: '#CBC9FF',
                primaryColor: '#0F0000',
                primaryShadow: 'none',
                defaultShadow: 'none',
                colorTextDisabled: 'rgba(23, 23, 23, 0.25)',
                colorBgContainerDisabled: 'rgba(23, 23, 23, 0.10)',
                colorBorder: 'transparent',
                paddingInlineLG: 24,
                fontWeight: 500,
                fontFamily: 'Poppins',
                borderRadiusLG: 12,
              },
              Progress: {
                defaultColor: '#7C78FF',
                remainingColor: '#1717171A',
                colorSuccess: '#7C78FF',
              },
              Select: {
                borderRadiusLG: 12,
              },
              Input: {
                borderRadiusLG: 12,
                activeBorderColor: "#7C78FF",
                hoverBorderColor: "none"
              },

              //   Modal: {},
            },
            token: {
              colorPrimary: '#CBC9FF',
            },
          }}
        >
          <AuthUserContext.Provider
            value={{ authUser, setAuthUser, signIn, signOut }}
          >
            <CurrentUserContext.Provider
              value={{ currentUser, setCurrentUser, loadUser }}
            >
              <RouterProvider router={AppRouter} />
            </CurrentUserContext.Provider>

            {/* {authUser ? <AuthApp /> : <UnauthApp />} */}
          </AuthUserContext.Provider>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default App;
