import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './EventDatePicker.module.css';
import { DefaultOptionType } from 'antd/es/select';

interface EventDatePickerProps {
  dateLabel: string;
  month: number;
  day: number;
  year: number;
  setMonth: (month: number) => void;
  setDay: (day: number) => void;
  setYear: (year: number) => void;
  firstInput?: boolean
}

export default function EventDatePicker({
  dateLabel,
  month,
  day,
  year,
  setMonth,
  setDay,
  setYear,
  firstInput
}: EventDatePickerProps) {
  const today = new Date();

  const months = [
    { value: 'January', label: 'January', days: 31 },
    { value: 'February', label: 'February', days: 28 },
    { value: 'March', label: 'March', days: 31 },
    { value: 'April', label: 'April', days: 30 },
    { value: 'May', label: 'May', days: 31 },
    { value: 'June', label: 'June', days: 30 },
    { value: 'July', label: 'July', days: 31 },
    { value: 'August', label: 'August', days: 31 },
    { value: 'September', label: 'September', days: 30 },
    { value: 'October', label: 'October', days: 31 },
    { value: 'November', label: 'November', days: 30 },
    { value: 'December', label: 'December', days: 31 },
  ];

  const years = Array(120)
    .fill(0)
    .map((_, i) => today.getFullYear() - i);

  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (<>
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: isLaptopOrDesktop ? 'row' : 'column',
        justifyContent: 'space-between',
        // gap: '20px',
        rowGap: isLaptopOrDesktop ? '5px' : '20px',
        columnGap: '20px',
        flexWrap: 'wrap',
        paddingTop: isLaptopOrDesktop ? 0 : '20px'
      }}
    >
      <div className={styles.label}>{dateLabel}</div>
      <Select
        id={firstInput && "firstInput"}
        size="large"
        options={months}
        // value={months[month - 1].value}
        placeholder="Month"
        style={{ width: isLaptopOrDesktop ? '30%' : '100%' }}
        onChange={(value) => {
          const newMonth = months.findIndex((element) => element.value === value.toString())
          setMonth(newMonth + 1)
          if(day !== 0 && months[newMonth].days < day) {
            setDay(months[newMonth].days)
          }
        }}
      />
      <Select
        size="large"
        style={{ width: isLaptopOrDesktop ? '30%' : '100%' }}
        value={day === 0 ? undefined : day}
        placeholder="Day"
        onChange={(value) => setDay(value)}
        disabled={month === 0}
        options={
          [...Array(months[month === 0 ? 0 : month - 1].days).keys()]
            .map((day) => ({ value: day + 1 }))
        }
      />
      <Select
        size="large"
        options={years.map((year) => {
          return { value: year };
        })}
        onChange={(value) => setYear(value)}
        style={{ width: isLaptopOrDesktop ? '30%' : '100%' }}
        // value={year}
        placeholder="Year"
        disabled={day === 0}
      />
    </div>
  </>);
}