import { Button, Input, Select, Space } from 'antd';
import React, { useState } from 'react';

import styles from './CompleteProfile.module.css';
import { API } from 'aws-amplify';
import {
  CurrentUserContextType,
  useCurrentUserContext,
} from '../../../../store';
import { useMediaQuery } from 'react-responsive';

interface CompleteProfileProps {
  onSubmit?: () => void;
}

export default function CompleteProfile({ onSubmit }: CompleteProfileProps) {
  const { currentUser, loadUser }: CurrentUserContextType =
    useCurrentUserContext();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pronoun, setPronoun] = useState();

  const [loading, setLoading] = useState(false);

  const today = new Date();

  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [year, setYear] = useState(0);

  const months = [
    { value: 'January', label: 'January', days: 31 },
    { value: 'February', label: 'February', days: 28 },
    { value: 'March', label: 'March', days: 31 },
    { value: 'April', label: 'April', days: 30 },
    { value: 'May', label: 'May', days: 31 },
    { value: 'June', label: 'June', days: 30 },
    { value: 'July', label: 'July', days: 31 },
    { value: 'August', label: 'August', days: 31 },
    { value: 'September', label: 'September', days: 30 },
    { value: 'October', label: 'October', days: 31 },
    { value: 'November', label: 'November', days: 30 },
    { value: 'December', label: 'December', days: 31 },
  ];


  const years = Array(120)
    .fill(0)
    .map((_, i) => today.getFullYear() - i);

  const pronouns = [
    { value: 'her', label: 'her' },
    { value: 'his', label: 'his' },
    { value: 'their', label: 'their' },
  ];

  const submit = async () => {
    setLoading(true);
    try {
      const apiName = 'api';
      const path = '/api/users/currentUser';
      const init = {
        body: {
          firstName,
          lastName,
          pronoun,
          _version: currentUser._version,
        },
      };

      const res = await API.put(apiName, path, init);
      const data = await res.data;

      const path2 = '/api/events/currentUser';
      const init2 = {
        body: {
          eventType: 'BIRTHDAY',
          date: new Date(year, month - 1, day).toISOString(),
          fields: '{}',
          status: 'PUBLIC',
        },
      };

      const res2 = await API.post(apiName, path2, init2);
      const data2 = await res2.data;

      await loadUser();
      onSubmit && onSubmit();
    } catch(error) {
    } finally {
      setLoading(false);
    }
  };

  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.inputWrap}>
          <div className={styles.label}>First name</div>
          <Input
            className={styles.input}
            size="large"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            autoFocus={true}
          />
        </div>

        <div className={styles.inputWrap}>
          <div className={styles.label}>Last name</div>
          <Input
            className={styles.input}
            size="large"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.inputWrap}>
          <div className={styles.label}>Proni</div>
          <Select
            style={{ width: '100%' }}
            size="large"
            value={pronoun}
            options={pronouns}
            onChange={(value) => setPronoun(value)}
            placeholder="Pick your proni"
          />
          <div className={styles.description}>
            {
              isLaptopOrDesktop ? <>Possesive pronouns help Celi send grammatically correct notifications</>
                : <>Pronouns help us send correct notifications</>
            }

          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.inputWrap}>
          <div className={styles.label}>When's your birthday?</div>

          <Select
            size="large"
            options={months}
            placeholder="Month"
            // value={months[month - 1].value}
            style={{ width: '100%' }}
            onChange={(value) => {
              const newMonth = months.findIndex((element) => element.value === value.toString())
              setMonth(newMonth + 1)
              console.log({
                day,
                month,
                value,
                newMonth
              })
              if(day !== 0 && months[newMonth].days < day) {
                setDay(months[newMonth].days)
              }
            }}
          />
        </div>
        <div className={styles.inputWrap}>
          <Select
            size="large"
            style={{ width: '100%' }}
            placeholder="Day"
            value={day === 0 ? undefined : day}
            disabled={month === 0}
            onChange={(value) => setDay(value)}
            options={[...Array(months[month === 0 ? 0 : month - 1].days).keys()]
              .map((day) => ({ value: day + 1 }))}
          />
        </div>
        <div className={styles.inputWrap}>
          <Select
            size="large"
            placeholder="Year"
            options={years.map((year) => {
              return { value: year };
            })}
            onChange={(value) => setYear(value)}
            style={{ width: '100%' }}
            disabled={day === 0}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Button
          loading={loading}
          size="large"
          type="primary"
          onClick={submit}
          disabled={firstName === '' || lastName === '' || pronoun === undefined || month === 0 || day === 0 || year === 0}
        >
          Next
        </Button>
      </div>
    </div>
  );
}