import React from 'react';
import { Header as AntHeader } from 'antd/es/layout/layout';
import Link from 'antd/es/typography/Link';
import { Button, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuthUserContext, useCurrentUserContext } from '../../store';

import styles from './Header.module.css';
import { useMediaQuery } from 'react-responsive';

interface HeaderProps {
  signIn?: boolean;
  signUp?: boolean;
  signOut?: boolean;
  username?: boolean;
}
const redirectMap = {
  "https://dev-onboarding.timetoceli.com":"https://app-dev.timetoceli.com/",
  "https://test-onboarding.timetoceli.com": "https://app-test.timetoceli.com/",
  "https://onboarding.timetoceli.com": "https://app.timetoceli.com/",
  "http://localhost:3000": "http://localhost:3001",
} 

export default function Header({
  signIn = false,
  signUp = false,
  signOut = false,
  username = false,
}: HeaderProps = {}) {
  const navigate = useNavigate();
  const userSignOut = useAuthUserContext().signOut;
  const { currentUser } = useCurrentUserContext();

  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <AntHeader
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: ' 0px 4px 4px 0px rgba(237, 231, 251, 0.15)',
      }}
      className={styles.header}
    >
      <Link href="https://www.timetoceli.com">
        <Image preview={false} src="celi-logo.png" height={36} width={73}></Image>
      </Link>
      {signIn && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Link className={styles.link} href={redirectMap[window.location.origin] || 'https://app.timetoceli.com'}>
            Sign In
          </Link>
        </div>
      )}
      {signUp && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div>Don't have an account?</div>
          <Link className={styles.link} href="/">
            Sign Up
          </Link>
        </div>
      )}
      {signOut && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Link className={styles.link} onClick={() => userSignOut()}>
            {' '}
            Sign Out
          </Link>
        </div>
      )}
      {username && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          className={styles.username}
        >
          @{currentUser?.username}
        </div>
      )}
    </AntHeader>
  );
}