import { Button, Checkbox, Collapse, Drawer, Input, Modal, Progress } from 'antd';
import { API, Auth, Hub } from 'aws-amplify';
import "./phoneInput.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthUserContextType,
  CurrentUserContextType,
  useAuthUserContext,
  useCurrentUserContext,
} from '../../store';
import VerifyInput from './(components)/VerifyInput/VerifyInput';

import styles from './Verification.module.css';
import Header from '../../components/header/Header';
import { Content } from 'antd/es/layout/layout';
import { useMediaQuery } from 'react-responsive';
import PhoneInput, { CountryData } from 'react-phone-input-2';


const validateEmail = (email) => {
  const regex = /^[\w\.-]+(?:\+[\w-]*)?@[A-Za-z\d\.-]+\.[A-Za-z]{2,4}$/;
  return regex.test(email);
}

export default function Verification() {
  const { currentUser, loadUser }: CurrentUserContextType =
    useCurrentUserContext();


  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [newPhone, setNewPhone] = useState("")
  const [newCountryCode, setNewCountryCode] = useState("us")
  const [newEmail, setNewEmail] = useState("")
  const [error, setError] = useState("")
  const [time, setTime] = useState(40);
  const [isFinished, setIsFinished] = useState(false);

  const emailVerified = currentUser?.emailVerified;
  const phoneVerified = currentUser?.phoneVerified;

  const navigate = useNavigate();

  const resetTimer = () => {
    setTime(40);
    setIsFinished(false);
  };


  const sendPhoneCode = async () => {
    try {
      const apiName = 'api';
      const path = '/api/users/currentUser/verify/phone';
      const init = {};

      const res = await API.get(apiName, path, init);
      const data = await res.data;
    } catch(error) {}
  };

  const sendEmailCode = async () => {
    try {
      const res = await Auth.verifyCurrentUserAttribute('email');
    } catch(error) {}
  };

  // const emailVerificationComplete = () => {sendPhoneCode()};
  const emailVerificationComplete = async () => {
    await loadUser();
    sendPhoneCode();
  };

  const phoneVerificationComplete = async () => {
    await loadUser();
  };

  useEffect(() => {
    if(!emailVerified) {
      sendEmailCode();
    } else if(!phoneVerified) {
      sendPhoneCode();
    }
  }, []);


  useEffect(() => {
    document.getElementById("newEmailInput")?.focus()
    document.getElementById("newPhoneInput")?.focus()
  }, [openModal])


  const updateEmail = async () => {
    setLoading(true);
    console.log(newEmail)
    try {
      const apiName = 'api';
      const path = '/api2/users/public/checkuser';
      const myInit = {
        body: {
          email: newEmail,
          phone: "",
        },
        headers: {}, // OPTIONAL
      };

      let check = await API.post(apiName, path, myInit);
      try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await Auth.updateUserAttributes(user, { email: newEmail })

        const apiName = 'api';
        const path = '/api/users/currentUser';
        const init = {
          body: {
            email: newEmail,
            _version: currentUser._version,
          },
        };

        const res = await API.put(apiName, path, init);
        sendEmailCode()
        resetTimer()
        setNewEmail("")
        loadUser()
        setError("")
      } catch(error) {
        console.log(error)
      } finally {
        setLoading(false)
        setOpenModal(false)
      }
    } catch(error) {
      const { data: { body } } = error.response;
      if(body.errors[0].message === 'emailAlreadyExists') {
        setError('This email is already in use');
      }
      if(body.errors[0].message === 'phoneAlreadyExists') {
        setError('This phone is already in use');
      }
      setLoading(false)
    }
  }

  const updatePhone = async () => {
    setLoading(true)
    try {
      const apiName = 'api';
      const path = '/api2/users/public/checkuser';
      const myInit = {
        body: {
          email: "",
          phone: '+' + newPhone,
        },
        headers: {}, // OPTIONAL
      };

      let check = await API.post(apiName, path, myInit);
      try {
        const apiName = 'api';
        const path = '/api/users/currentUser';
        const init = {
          body: {
            phone: '+' + newPhone,
            countryCode: newCountryCode,
            _version: currentUser._version,
          },
        };

        const res = await API.put(apiName, path, init);
        resetTimer()
        loadUser()
        sendPhoneCode()
        setNewPhone("+1")
        setError("")
      } catch(error) {
        console.log(error)
      } finally {
        setLoading(false)
        setOpenModal(false)
      }
    } catch(error) {
      const { data: { body } } = error.response;
      if(body.errors[0].message === 'emailAlreadyExists') {
        setError('This email is already in use');
      }
      if(body.errors[0].message === 'phoneAlreadyExists') {
        setError('This phone is already in use');
      }
      setLoading(false)
    }
  }

  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className={styles.main}>
      <Header />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.progressContainer}>
            <Progress percent={100} size={[isLaptopOrDesktop ? 123 : 110, 4]} showInfo={false} />
            <Progress percent={0} size={[isLaptopOrDesktop ? 123 : 110, 4]} showInfo={false} />
            <Progress percent={0} size={[isLaptopOrDesktop ? 123 : 110, 4]} showInfo={false} />
          </div>
          <h4 className={styles.header}>Verification</h4>
          <div className={styles.text}>
            {isLaptopOrDesktop ? (
              <>
                Celi uses Double Identity Verification®<br />
                as an extra layer of security to confirm and protect your identity.
              </>
            ) : (
              <>
                Celi uses Double Identity Verification® as an <br />
                extra layer of security to protect your identity.
              </>
            )}
          </div>
          <div className={styles.collapseWrapper}>
            <Collapse
              className={styles.collapse}
              expandIcon={() => null}
              size="large"
              activeKey={!emailVerified ? ['1'] : []}
              items={[
                {
                  key: '1',
                  headerClass: styles.collapseHeader,
                  label: (
                    <div className={styles.collapseLabel}>
                      Verify {currentUser.email}
                    </div>
                  ),
                  extra: <Checkbox checked={emailVerified} />,
                  children: (
                    <>
                      <VerifyInput
                        channel={'EMAIL'}
                        onSuccessfulConfirm={emailVerificationComplete}
                        reSendCode={sendEmailCode}
                        setOpenModal={setOpenModal}
                        emailVerified={emailVerified}
                        resetTimer={resetTimer}
                        setTime={setTime}
                        setIsFinished={setIsFinished}
                        time={time}
                        isFinished={isFinished}
                      />
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className={styles.collapseWrapper}>
            <Collapse
              className={styles.collapse}
              expandIcon={() => null}
              size="large"
              activeKey={emailVerified && !phoneVerified ? ['1'] : []}
              items={[
                {
                  key: '1',
                  label: (
                    <div className={styles.collapseLabel}>
                      Verify {currentUser.phone}
                    </div>
                  ),
                  headerClass: styles.collapseHeader,
                  extra: <Checkbox checked={phoneVerified} />,
                  children: (
                    <>
                      <VerifyInput
                        channel={'PHONE'}
                        onSuccessfulConfirm={phoneVerificationComplete}
                        reSendCode={sendPhoneCode}
                        setOpenModal={setOpenModal}
                        emailVerified={emailVerified}
                        resetTimer={resetTimer}
                        setTime={setTime}
                        setIsFinished={setIsFinished}
                        time={time}
                        isFinished={isFinished}
                      />
                    </>
                  ),
                },
              ]}
            />
          </div>



          {emailVerified && phoneVerified && (
            <div>
              <div className={styles.successText}>
                Thanks for keeping Celi safe, secure, and{' '}
                <span style={{ textDecoration: 'underline' }}>celi</span>bratory
                for everyone!
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  size="large"
                  type="primary"
                  onClick={() => navigate('/identity')}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {isLaptopOrDesktop ?
            <Modal
              forceRender={true}
              open={openModal}
              centered
              afterOpenChange={(open) => {
                document.getElementById("newEmailInput")?.focus()
                document.getElementById("newPhoneInput")?.focus()
              }}
              onCancel={() => setOpenModal(false)}
              footer={[
                <Button
                  size='large'
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    if(!emailVerified) {
                      updateEmail()
                    } else {
                      updatePhone()
                    }
                  }}
                  disabled={!emailVerified ? !validateEmail(newEmail) : newPhone && newCountryCode ? !((newPhone.length - newCountryCode.length) >= 9 && (newPhone.length - newCountryCode.length) <= 15) : true}
                >
                  Send code
                </Button>,
              ]}
            >
              <UpdateForm newEmail={newEmail}
                setNewEmail={setNewEmail}
                newPhone={newPhone}
                setNewPhone={setNewPhone}
                newCountryCode={newCountryCode}
                setNewCountryCode={setNewCountryCode}
                emailVerified={emailVerified}
                currentUser={currentUser}
              />
              <div className={styles.error}>{error}</div>
            </Modal> :
            <Drawer
              open={openModal}
              placement='bottom'
              height="initial"
              onClose={() => setOpenModal(false)}
              afterOpenChange={(open) => {
                document.getElementById("newEmailInput")?.focus()
                document.getElementById("newPhoneInput")?.focus()
              }}
              className={styles.drawerTitle}
              closeIcon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_813_23335)"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black" /></g>
                  <defs>
                    <clipPath id="clip0_813_23335">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
            >
              <div style={{
                margin: "0 30px 30px 30px"
              }}>
                <UpdateForm newEmail={newEmail}
                  setNewEmail={setNewEmail}
                  newPhone={newPhone}
                  setNewPhone={setNewPhone}
                  newCountryCode={newCountryCode}
                  setNewCountryCode={setNewCountryCode}
                  emailVerified={emailVerified}
                  currentUser={currentUser}
                />
                <div className={styles.error} style={{ marginBottom: "20px" }}>{error}</div>
                <Button
                  size='large'
                  type="primary"
                  block
                  loading={loading}
                  onClick={() => {
                    if(!emailVerified) {
                      updateEmail()
                    } else {
                      updatePhone()
                    }
                  }}
                  disabled={!emailVerified ? !validateEmail(newEmail) : newPhone && newCountryCode ? !((newPhone.length - newCountryCode.length) >= 9 && (newPhone.length - newCountryCode.length) <= 15) : true}
                >
                  Send code
                </Button>
              </div>

            </Drawer>
          }
        </div>
      </Content>
    </div>
  );
}

const UpdateForm = ({ newEmail, setNewEmail, newPhone, newCountryCode, setNewPhone, setNewCountryCode, emailVerified, currentUser }) => {
  return (
    <>
      <div className={styles.row}><p className={styles.headerModal}>Enter new {!emailVerified ? "email" : "number"}</p></div>
      {!emailVerified ?
        <>
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.collapseLabel}>Old Email</div>
              <Input
                value={currentUser.email}
                className={styles.input}
                size="large" disabled
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.collapseLabel}>New Email</div>
              <Input
                size="large"
                className={styles.input}
                placeholder="example@gmail.com"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                id="newEmailInput"
              />
            </div>
          </div>
        </> :
        <>
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.collapseLabel}>Old Number</div>
              <PhoneInput
                country={currentUser.countryCode}
                countryCodeEditable={false}
                enableSearch={true}
                value={currentUser.phone}
                placeholder="Phone Number"
                inputClass={styles.inputField}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.collapseLabel}>New Number</div>
              <PhoneInput
                country={newCountryCode}
                countryCodeEditable={false}
                enableSearch={true}
                value={newPhone}
                inputClass={styles.inputField}
                onChange={(phone, country: CountryData) => {
                  setNewPhone(phone);
                  setNewCountryCode(country.dialCode);
                }}
                inputProps={{
                  id: "newPhoneInput"
                }}
              />
            </div>
          </div>
        </>
      }
    </>
  )
}
