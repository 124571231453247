import { Button, Input } from 'antd';
import { API, Auth, Hub } from 'aws-amplify';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import styles from './VerifyInput.module.css';
import { useMediaQuery } from 'react-responsive';

interface VerifyInputProps {
  channel: 'EMAIL' | 'PHONE';
  onSuccessfulConfirm: () => Promise<void>;
  reSendCode: () => Promise<void>,
  setOpenModal: Dispatch<SetStateAction<boolean>>,
  emailVerified: boolean,
  resetTimer: () => void,
  setTime: (value: React.SetStateAction<number>) => void,
  setIsFinished: (value: React.SetStateAction<boolean>) => void
  time: number,
  isFinished: boolean,
}

export default function VerifyInput({
  channel,
  onSuccessfulConfirm,
  reSendCode,
  setOpenModal,
  emailVerified,
  resetTimer,
  setTime,
  setIsFinished,
  time,
  isFinished,
}: VerifyInputProps) {
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  // const [time, setTime] = useState(40);
  // const [isFinished, setIsFinished] = useState(false);
  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  // const resetTimer = () => {
  //   setTime(40);
  //   setIsFinished(false);
  // };

  const confirmEmail = async () => {
    setLoading(true);
    try {
      //   await Auth.confirmSignUp(currentUser.username, code);
      const response = await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        code
      );

      const apiName = 'api';
      const path = '/api/users/currentUser/verify/email';
      const init = { body: { verified: true } };

      const res = await API.post(apiName, path, init);
      const data = await res.data;
      await onSuccessfulConfirm();
    } catch(error) {
    } finally {
      setLoading(false);
    }
  };

  const confirmPhone = async () => {
    setLoading(true);
    try {
      const apiName = 'api';
      const path = '/api/users/currentUser/verify/phone';
      const init = { body: { verificationCode: code } };

      const res = await API.post(apiName, path, init);
      const data = await res.data;
      await onSuccessfulConfirm();
    } catch(error) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if(channel === 'EMAIL') {
      await confirmEmail();
    } else if(channel === 'PHONE') {
      await confirmPhone();
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    // const formatMinutes = 
    return `:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);
    if(time === 0) {
      clearInterval(timer);
      setIsFinished(true);
    }
    return () => clearInterval(timer);
  }, [time]);



  return (
    <div className={styles.main}>
      <div className={styles.labelWrap}>
        {channel === 'EMAIL' && (
          <div className={styles.label}>We emailed you a {isLaptopOrDesktop && "verification"} code</div>
        )}
        {channel === 'PHONE' && (
          <div className={styles.label}>We texted you a {isLaptopOrDesktop && "verification"} code</div>
        )}
        <div className={styles.resendLabel}>
          <Button
            onClick={() => {
              resetTimer()
              reSendCode()
            }}
            // type='text'
            className={styles.resendButton}
            disabled={!isFinished}>
            {isLaptopOrDesktop ? "Resend Code" : "Resend"}
          </Button>
          {!isFinished && <>in {formatTime(time)}</>}
        </div>
      </div>

      <Input
        size="large"
        value={code}
        placeholder="Enter code"
        onChange={(e) => setCode(e.target.value)}
        autoFocus={true}
      />
      {channel === 'EMAIL' && (
        <div className={styles.description}>
          {isLaptopOrDesktop ?
            (<>Search your inbox for no-reply@verificationemail.com</>) :
            (<>Sent from no-reply@verificationemail.com</>)
          }
        </div>
      )}
      {channel === 'PHONE' && (
        <div className={styles.description}>
          {isLaptopOrDesktop ?
            (<>Can’t find it? Search your known or unknown SMS folders</>) :
            (<>Search your known or unknown SMS folders</>)}
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          loading={loading}
          disabled={code.length < 6}
          type="primary"
          size="large"
          onClick={() => {
            resetTimer()
            onSubmit()
          }}
        >
          Verify
        </Button>
      </div>
      <div className={styles.buttonLinkWrapper}>
        <Button type='link' onClick={() => setOpenModal(true)}>
          <span className={styles.linkButton}>Update {!emailVerified ? "email address" : "phone number"}</span>
        </Button>
      </div>
    </div>
  );
}