import React from 'react';
import {
  Navigate,
  createHashRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAuthUserContext, useCurrentUserContext } from '../store';
import AppLayout from '../AppLayout';
import Landing from '../screens/landing/Landing';
import Verification from '../screens/verification/Verification';
import Identity from '../screens/identity/Identity';
import Events from '../screens/events/Events';
import ErrorBoundary from '../components/Error/ErrorBoundary';
import PacLink from '../screens/pacLink/Paclink';
const redirectMap = {
  "https://dev-onboarding.timetoceli.com": "https://app-dev.timetoceli.com/",
  "https://test-onboarding.timetoceli.com": "https://app-test.timetoceli.com/",
  "https://onboarding.timetoceli.com": "https://app.timetoceli.com/",
  "http://localhost:3000": "http://localhost:3001",
}
function RequireUnAuth({ children }: { children: JSX.Element }) {
  const { authUser } = useAuthUserContext();
  const { currentUser } = useCurrentUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if(location.pathname === '/' && authUser && currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    window.location.href = redirectMap[window.location.origin] || 'https://app.timetoceli.com';

  }

  return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { authUser } = useAuthUserContext();
  const { currentUser } = useCurrentUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if(!authUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  const birthday = currentUser.events?.items.find(event => event.type === 'BIRTHDAY')
  if(
    location.pathname !== '/verify' &&
    (!currentUser?.emailVerified || !currentUser?.phoneVerified)
  ) {
    return <Navigate to="/verify" replace />;
  } else if(
    location.pathname === '/verify' &&
    currentUser?.emailVerified &&
    currentUser?.phoneVerified
  ) {
    return <Navigate to="/identity" replace />;
  } else if(
    location.pathname !== '/identity' &&
    currentUser?.emailVerified &&
    currentUser?.phoneVerified &&
    !currentUser?.firstName
  ) {
    return <Navigate to="/identity" replace />;
  } else if(location.pathname === '/identity' && (!currentUser?.firstName || !currentUser?.lastName || !currentUser?.pronoun || !birthday)) {

  } else if(location.pathname === '/identity' && currentUser?.firstName) {
    window.location.href = redirectMap[window.location.origin] || 'https://app.timetoceli.com';

  }

  return children;
}

export const AppRouter = createHashRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <RequireUnAuth>
            <Landing />
          </RequireUnAuth>
        ),
      },
      {
        path: '/verify',
        element: (
          <RequireAuth>
            <ErrorBoundary>
              <Verification />
            </ErrorBoundary>
          </RequireAuth>
        ),
      },
      {
        path: '/identity',
        element: (
          <RequireAuth>
            <Identity />
          </RequireAuth>
        ),
      },
      {
        path: '/events',
        element: (
          <RequireAuth>
            <Events />
          </RequireAuth>
        ),
      },
      {
        path: '/pac-link',
        element: (
          <RequireAuth>
            <PacLink />
          </RequireAuth>
        ),
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);