import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './index.css';
import App from './App.tsx';
import {Amplify, Auth} from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const IS_LOCALHOST = document.location.hostname === 'localhost';

const cookieStorage = {
  domain: IS_LOCALHOST ? document.location.hostname : '.timetoceli.com',
  secure: !IS_LOCALHOST,
  path: '/',
  expires: 365,
};
Auth.configure({...awsExports, cookieStorage});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
