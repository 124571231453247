import { Button, Checkbox, Input } from 'antd';
import { API, Auth, Hub } from 'aws-amplify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import {
  useAuthUserContext,
  useCurrentUserContext,
  usePreapprovedConnectionLinkContext,
} from '../../../../store';
import { Typography } from 'antd';

import styles from './CreateAccountForm.module.css';
import './phoneInput.css';
import Link from 'antd/es/typography/Link';
import { useMediaQuery } from 'react-responsive';
const redirectMap = {
  "https://dev-onboarding.timetoceli.com": "https://app-dev.timetoceli.com/",
  "https://test-onboarding.timetoceli.com": "https://app-test.timetoceli.com/",
  "https://onboarding.timetoceli.com": "https://app.timetoceli.com/",
  "http://localhost:3000": "http://localhost:3001",
}
export default function CreateAccountForm({ referrer }) {
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();
  const { setCurrentUser, loadUser } = useCurrentUserContext();
  const { setAuthUser } = useAuthUserContext();
  const { preapprovedConnectionLink } = usePreapprovedConnectionLinkContext();

  useEffect(() => {
    const flagDropDownElement = document.querySelector(".selected-flag");

    if(flagDropDownElement) {
      flagDropDownElement.setAttribute('tabindex', "-1");
    }
  }, [])


  const validatePassword = (password: string) => {
    if(password === '') {
      return true;
    }

    if(password.includes(" ")) {
      return false
    }

    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if(
      password.length < 8 ||
      !hasNumber ||
      !hasSymbol ||
      !hasUppercase ||
      !hasLowercase
    ) {
      return false;
    }
    return true;
  };

  const submit = async () => {
    setError('');
    let authResponse;
    setLoading(true);
    try {
      const apiName = 'api';
      const path = '/api2/users/public/checkuser';
      const myInit = {
        body: {
          email,
          phone: '+' + phone,
        },
        headers: {}, // OPTIONAL
      };

      let check = await API.post(apiName, path, myInit);

      try {
        authResponse = await Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email, // optional
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        });

        setAuthUser(authResponse.user);
      } catch(error: any) {
        if(error.code === 'UsernameExistsException') {
          setError('This username is already in use');
        }
        if(error.code === 'InvalidParameterException' && error.message.includes('password')) {
          setError('Password must include a number, symbol, uppercase, and lowercase letter');
        }
        if(error.code === 'InvalidParameterException' && error.message.includes('email')) {
          setError('Invalid email');
        }
        setLoading(false);
        return;
      }

      const hubListenerCancel = Hub.listen('auth', async ({ payload }) => {
        const { event } = payload;
        if(event === 'autoSignIn') {
          const apiName = 'api';
          const path = '/api/users';
          const myInit = {
            body: {
              username,
              email,
              phone: '+' + phone,
              countryCode,
              source: preapprovedConnectionLink ? "referred" : "direct"
            },
            headers: {}, // OPTIONAL
          };

          let response;

          try {
            response = await API.post(apiName, path, myInit)
          } catch(error: any) {
            const { data: { body } } = error.response;
            if(body.errors[0].message === "Variable 'phone' has an invalid value.") {
              setError('Phone number invalid. Please update.');
              Auth.deleteUser().catch(error => console.log(error))
            }
            // if(body.errors[0].message === 'phoneAlreadyExists') {
            //   setError('This phone is already in use');
            // }

            setLoading(false);
            // await Auth.deleteUser();
            // localStorage.clear
            hubListenerCancel()
            return;
          }
          try {
            const apiName = "api";
            const path = "/api/connections";
            const myInit = {
              body: {
                username1: "celi",
                username2: username,
              },
              headers: {}, // OPTIONAL
            };
            let response;
            try {
              response = await API.post(apiName, path, myInit);
              console.log(response);
            } catch(error: any) {
              console.log("error");
            }
            const myInit2 = {
              body: {
                username1: username,
                username2: "celi",
              },
              headers: {}, // OPTIONAL
            };
            let response2;

            try {
              response2 = await API.post(apiName, path, myInit2);
              console.log(response2);
            } catch(error: any) {
              console.log("error");
            }
          } catch(error) {
            console.log(error)
          }
          try {
            if(preapprovedConnectionLink) {
              const apiName = 'api';
              const path = '/api/connections/currentUser/pacl';
              const myInit = {
                body: {
                  preapprovedConnectionLink: preapprovedConnectionLink,
                },
                headers: {}, // OPTIONAL
              };

              let response;

              try {
                response = await API.post(apiName, path, myInit);
              } catch(error: any) {}
            }
          } catch(error) {
            const { data: { body } } = error.response;
            hubListenerCancel()

          }

          await loadUser();
          navigate('/verify');
        } else if(event === 'autoSignIn_failure') {
          // redirect to sign in page
          window.location.href = redirectMap[window.location.origin] || 'https://app.timetoceli.com';
        }
      });
    } catch(error) {
      const { data: { body } } = error.response;
      if(body.errors[0].message === 'emailAlreadyExists') {
        setError('This email is already in use');
      }
      if(body.errors[0].message === 'phoneAlreadyExists') {
        setError('This phone is already in use');
      }

      setLoading(false);
      localStorage.clear()
      return;
    }


  };

  const handleKeyDown = (event, nextInputId) => {
    if(event.key === 'Enter') {
      event.preventDefault();
      document.getElementById(nextInputId).focus();
    }
  };

  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className={styles.shadowWrap}>
      <div className={styles.main}>
        <div className={styles.header}>
          {
            !preapprovedConnectionLink ?
              <>
                <p className={styles.subheader}>Start <span>celi</span>brating</p>
                {isLaptopOrDesktop ?
                  <p className={styles.subtext}>
                    Celi knows your friends’ most important dates and reminds you of them by text and WhatsApp.
                  </p> :
                  <p className={styles.subtext}>
                    and never miss a moment again
                  </p>}
              </> :
              <>
                <p className={styles.subheader}>{referrer}</p>
                <p className={styles.subtext}>wants to know your birthday and beyond dates.</p>
              </>

          }


        </div>
        <div className={styles.field}>
          <div className={styles.label}>Email address</div>
          <Input
            id='input-1'
            placeholder="example@gmail.com"
            allowClear
            className={styles.inputField}
            value={email}
            size="large"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            onKeyDown={(e) => handleKeyDown(e, 'input-2')}
            autoFocus={true}
          />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Phone number</div>
          <PhoneInput
            inputProps={{
              id: "input-2"
            }}
            country={'us'}
            countryCodeEditable={false}
            enableSearch={true}
            value={phone}
            placeholder="Phone Number"
            inputClass={styles.inputField}
            onChange={(phone, country: CountryData) => {
              setPhone(phone);
              setCountryCode(country.dialCode);
            }}
            onKeyDown={(e) => handleKeyDown(e, 'input-3')}
          />
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Username</div>
          <Input
            id='input-3'
            allowClear
            placeholder="Username"
            value={username}
            size="large"
            maxLength={30}
            className={styles.inputField}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            status={username.includes(" ") && "error"}
            onKeyDown={(e) => handleKeyDown(e, 'input-4')}
          />
          <div className={styles.description}>
            Usernames help your friends find and identify you on Celi
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Password</div>
          <Input.Password
            id='input-4'
            placeholder="Password"
            value={password}
            size="large"
            maxLength={24}
            className={styles.inputField}
            status={validatePassword(password) ? '' : 'error'}
            onChange={(event) => {
              setIsPasswordValid(validatePassword(event.target.value))
              setPassword(event.target.value);
            }}
            onKeyDown={(e) => handleKeyDown(e, 'input-5')}
          />
          <div className={styles.description}>
            <div>
              Must include a number, symbol, uppercase, and lowercase letter
            </div>
            <div>
              <span>{password.length}</span> / 24
            </div>
          </div>
        </div>
        <div className={styles.checkboxField}>
          <div className={styles.checkboxText}>
            By creating my account, I agree to Celi's{' '}
            <Link
              className={styles.checkboxTextLink}
              style={{ color: '#848282' }}
              underline
              href="https://www.timetoceli.com/terms"
              target="_blank"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              className={styles.checkboxTextLink}
              style={{ color: '#848282' }}
              underline
              href="https://www.timetoceli.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            . I consent to receive email, SMS, and WhatsApp notifications from Celi at the contact information provided. Rates may apply.
          </div>
        </div>
        <div className={styles.error}>{error}</div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            size="large"
            type={'primary'}
            disabled={!(email && username && password && phone && isPasswordValid)}
            onClick={async () => {
              submit();
            }}
            loading={loading}
          >
            Create my account
          </Button>
        </div>
      </div>
    </div>
  );
}