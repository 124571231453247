import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function AppLayout() {
  return (
    <Layout
      style={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}
    >
      {/* <Header /> */}
      <Outlet />
    </Layout>
  );
}
