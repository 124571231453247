import { Button, DatePicker, Form, Input, Progress, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import styles from './Identity.module.css';
import { Content } from 'antd/es/layout/layout';
import CompleteProfile from './(components)/CompleteProfile/CompleteProfile';

export default function Identity() {
  const navigate = useNavigate();

  return (
    <div className={styles.main}>
      <Header username />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.progressContainer}>
            <Progress percent={100} size={[123, 4]} showInfo={false} />
            <Progress percent={100} size={[123, 4]} showInfo={false} />
            <Progress percent={0} size={[123, 4]} showInfo={false} />
          </div>
          <div className={styles.header}>Complete your profile</div>
          <div className={styles.content}>
            <div className={styles.formWrap}>
              <CompleteProfile onSubmit={() => navigate('/events')} />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
}
