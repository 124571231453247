import { Button, Drawer, Progress, Tag } from 'antd';
import React, { useState } from 'react';
import EventCreator from './(components)/EventCreator/EventCreator';
import styles from './Events.module.css';
import Header from '../../components/header/Header';
import { Content } from 'antd/es/layout/layout';
import { useCurrentUserContext } from '../../store';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export default function Events() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false)
  const { currentUser, loadUser } = useCurrentUserContext();
  const events = currentUser?.events.items;
  const [currentEventType, setCurrentEventType] = useState<any>(null);
  const eventTypes = [
    {
      value: "BIRTHDAY",
      label: "Birthday",
      fields: [],
      color: "#CBC9FF",
      strongColor: "#7C78FF",
    },
    {
      value: 'ANNIVERSARY',
      label: 'Anniversary',
      fields: [],
      color: '#F0E1E1',
      strongColor: '#D73120',
      drawerHeight: "60%",
    },
    {
      value: 'WORKIVERSARY',
      label: 'Workiversary',
      fields: ['Company_Name'],
      color: '#E7EFFF',
      strongColor: '#004DFF',
      drawerHeight: "70%",
    },
    {
      value: 'REMEMBRANCE_DAY',
      label: 'Remembrance Day',
      fields: [
        'Event_Subject_First_Name',
        'Event_Subject_Last_Name',
        'Event_Subject_Relationship',
      ],
      color: '#F5F5F4',
      strongColor: '#8C8B82',
      drawerHeight: "85%",
    },
    {
      value: 'SOBERVERSARY',
      label: 'Soberversary',
      fields: [],
      color: '#FBF3DF',
      strongColor: '#D8A018',
      drawerHeight: "55%",
    },
    {
      value: 'OUTVERSARY',
      label: 'Outversary',
      fields: [],
      color: '#F7F7C4',
      strongColor: '#004DFF',
      drawerHeight: "57%",
    },
    {
      value: 'REBIRTHDAY',
      label: 'Rebirthday',
      fields: [],
      color: '#DAEDF7',
      strongColor: '#FF66AE',
      drawerHeight: "53%",
    },
    {
      value: 'HEALTHVERSARY',
      label: 'Healthversary',
      fields: ['Description'],
      color: '#D4F8D3',
      strongColor: '#00BC4A',
      drawerHeight: "60%",
    },
    {
      value: 'CHILD_BIRTHDAY',
      label: 'Child\'s Birthday',
      fields: [
        'Event_Subject_Child_First_Name',
        'Event_Subject_Child_Relationship',
      ],
      color: '#FFF0BB',
      strongColor: '#CF9B17',
      drawerHeight: "65%",
    },
    {
      value: 'PARENT_ADOPTIONVERSARY',
      label: 'Parent Adoptionversary',
      fields: ['Event_Subject_Child_Relationship', 'Event_Subject_Child_Name'],
      color: '#F7E9DF',
      strongColor: '#FF8F4C',
      drawerHeight: "65%",
    },
    {
      value: 'CHILD_ADOPTIONVERSARY',
      label: 'Child Adoptionversary',
      fields: [],
      color: '#FFD1AF',
      strongColor: '#FF8F4C',
      drawerHeight: "55%",
    },
    {
      value: 'CITIZENSHIPVERSARY',
      label: 'Citizenshipversary',
      fields: ['Country'],
      color: '#E5CDE0',
      strongColor: '#A62F8B',
      drawerHeight: "60%",
    },
    {
      value: 'ARRIVAL_DAY',
      label: 'Arrival Day',
      fields: ['Country'],
      color: '#DDE5F6',
      strongColor: '#004DFF',
      drawerHeight: "60%",
    },

    {
      value: 'PET_BIRTHDAY',
      label: "Pet's Birthday",
      fields: ['Pet_Name', 'Pet_Type'],
      color: '#F7E0C4',
      strongColor: '#FF8B00',
      drawerHeight: "64%",
    },

    {
      value: 'FOUNDER_DAY',
      label: "Founder's Day",
      fields: ['Company_Name'],
      color: '#CBD5E1',
      strongColor: '#23548F',
      drawerHeight: "60%",
    },
  ];
  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const redirectMap = {
    "https://dev-onboarding.timetoceli.com": "https://app-dev.timetoceli.com/",
    "https://test-onboarding.timetoceli.com": "https://app-test.timetoceli.com/",
    "https://onboarding.timetoceli.com": "https://app.timetoceli.com/",
    "http://localhost:3000": "http://localhost:3001",
  }

  return (
    <div className={styles.main}>
      <Header username />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.progressContainer}>
            <Progress percent={100} size={[123, 4]} showInfo={false} />
            <Progress percent={100} size={[123, 4]} showInfo={false} />
            <Progress percent={100} size={[123, 4]} showInfo={false} />
          </div>
          <h4 className={styles.header}>
            {isLaptopOrDesktop ?
              <>
                Now, add your <span style={{ textDecoration: 'underline' }}>own</span>{' '}annual dates
              </> :
              <>
                Add your <span style={{ textDecoration: 'underline' }}>own</span>{' '}dates
              </>}

          </h4>
          <div className={styles.text}>
            {isLaptopOrDesktop ?
              <>
                To add a new date, click on the date name, enter your information, press save, and repeat.<br />
                You can add as many of your own dates as you’d like, including multiples of the same type.
              </> :
              <>
                To add a new date, click on the date name,<br />
                enter your information, press save, and repeat.
              </>}
          </div>
          <div className={styles.events}>
            {eventTypes.map(({ value, label, color, strongColor }, index) => {
              const buttonStyle = {
                color: currentEventType === value ? strongColor : 'black',
                backgroundColor: color,
                border:
                  currentEventType === value ? `2px solid ${strongColor}` : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '4px',
              };

              return (
                <>
                  {isLaptopOrDesktop && index !== 0 && index % 5 === 0 && (
                    <div className={styles.lineBreak} key={index} />
                  )}
                  <Button
                    className={styles.eventButton}
                    style={buttonStyle}
                    block={!isLaptopOrDesktop}
                    onClick={() => {
                      if(value === "BIRTHDAY") {
                        return
                      }
                      setCurrentEventType(null); // used to rerender the event creator so the fields are reset
                      setTimeout(() => setCurrentEventType(value), 10);
                      if(!isLaptopOrDesktop) {
                        setOpenDrawer(true);
                      }
                    }}
                  >
                    {label}{' '}
                    {events &&
                      events.filter((event: any) => event.type === value)
                        .length > 0 && (
                        <Tag
                          color={color}
                          style={{
                            color: strongColor,
                            border: `1px solid ${strongColor}`,
                            borderRadius: 12,
                            padding: '0 12px',
                            backgroundColor: strongColor + '30',
                            fontFamily: 'var(--secondary-font-family)',
                            fontSize: '15px',
                            fontWeight: 800,
                            marginRight: 0,
                          }}
                        >
                          {events &&
                            events.filter((event: any) => event.type === value)
                              .length}
                        </Tag>
                      )}
                  </Button>
                </>
              );
            })}
          </div>

          {currentEventType && (
            <div className={styles.eventCreatorContent}>
              {isLaptopOrDesktop ?
                <EventCreator
                  eventType={currentEventType}
                  onEventSubmit={async () => {
                    await loadUser();
                    setCurrentEventType(null);
                    // setEvents((prev: any) => [...prev, eventObj]);
                  }}
                />
                : <Drawer
                  open={openDrawer}
                  placement='bottom'
                  // size='large'
                  // height={eventTypes.find(({value})=>value=== currentEventType).drawerHeight}
                  height="initial"
                  onClose={() => setOpenDrawer(false)}
                  afterOpenChange={() => {
                    document.getElementById("firstInput")?.focus()
                  }}
                  className={styles.drawerTitle}
                  closeIcon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_813_23335)"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black" /></g>
                      <defs>
                        <clipPath id="clip0_813_23335">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                >
                  <EventCreator
                    eventType={currentEventType}
                    onEventSubmit={async () => {
                      await loadUser();
                      setCurrentEventType(null);
                      // setEvents((prev: any) => [...prev, eventObj]);
                    }}
                  />
                </Drawer>
              }
            </div>
          )}
          <div className={styles.submitButtonWrapper}>
            {events && events.length < 3 ? (
              <Button
                className={styles.submitButton}
                style={{ opacity: 0.5 }}
                size="large"
                onClick={() => navigate('/pac-link')}
              >
                I've added all my dates
              </Button>
            ) : (
              <Button
                className={styles.submitButton}
                type="primary"
                size="large"
                onClick={() => navigate('/pac-link')}
              >
                I've added all my dates
              </Button>
            )}
          </div>
        </div>
      </Content>
    </div>
  );
}